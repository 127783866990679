import React, { useState } from 'react'
import Lottie from 'lottie-web';
import endAnimationData from '../animations/endsession.json';
import { useRecoilValue } from 'recoil';
import { scoreState } from '../game/atom';

export const SessionEnd = ({currentSession, totalPoints, handleNextLevel, isLoading, updateLevel}) => {
    const animationContainer = React.createRef();
    const savedScore = useRecoilValue(scoreState);
    const [startBtnVisibility, setStartBtnVisibility] = useState('hide');
    const audioBtn = new Audio("./sfx/nextLevel/Positive Game Win v2.mp3");
    const audioWelcome = new Audio("./sfx/welcome/Slot Game Win v2.mp3")
    let lottieAnim;

    const handleButtonClickLeaderboard = () => {
        // console.log('restartGame', sessionState.gameState.level);
        document.getElementById('loader-init').style.display = "block";
        updateLevel(4);
        audioBtn.play();
      }

    React.useEffect(() => {
        const anim = Lottie.loadAnimation({
          container: animationContainer.current,
          animationData: endAnimationData,
          autoplay: false,
          loop: false
        });
        lottieAnim = anim;
        lottieAnim.stop();
        lottieAnim.addEventListener('loaded_images', function() {
            lottieAnim.play(); 
            setStartBtnVisibility('show');
            document.getElementById('loader-init').style.display = "none";
        });
        const timer = setTimeout(() => {
            // console.log('This will run after 1 second!');
            audioWelcome.play();
          }, 1000);
          
          return () => {
              clearTimeout(timer);
          }
          
    }, [])

    return (
        <div>
            <div className='end-session'>
                <div className='c-container' ref={animationContainer}>
                    <div className={`finalPoints ${startBtnVisibility}`}>{savedScore == 0 ? currentSession.gameState.totalPoints : savedScore}</div>
                    <div className='btn continue_btn opacity-0' onMouseDown={handleButtonClickLeaderboard}> <img src='./images/btns/NASTAVI.png' /></div>
                </div>
            </div>
        </div>
    )
}
