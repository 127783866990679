import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import Draggable from 'react-draggable'; // Both at the same time
import animation1 from '../animations/nivo1_bg.json';
import Lottie from 'lottie-web';

import { controlOptions, dotsState, scoreState, timerState, currentStakeState, caughtSymbolsState, symbolsState, individualScoreState } from './atom';
import { SPEED_STEP, SPAWN_INTERVAL } from './constants';
import { createDot, removeDot, calculatePoints } from './utils';
import Control from './Control';
import Dot from './Dot';
import Score from './Score';
import Timer from './Timer';
import ShowWonScore from './ShowWonScore';

const Game = ({ currentSession, updateLevel, isLoading, currentLevel, handleNextLevel, endScreen, bonusVockiceOpen, bonusVockiceTransfer }) => {
    const animationContainer = React.createRef();
    const [dots, updateDots] = useRecoilState(dotsState);
    const [controlState, setControlState] = useRecoilState(controlOptions);
    const [score, setScore] = useRecoilState(scoreState);
    const [individualScore, setIndividualScore] = useRecoilState(individualScoreState);
    // const [currentStake, setCurrentStake] = useRecoilState(currentStakeState);
    const [timer, setTimer] = useRecoilState(timerState);
    const [simboli, setSymbols] = useRecoilState(symbolsState);
    const requestRef = useRef();
    // const intervalRef = useRef();
    const timerRef = useRef();
    const fieldRef = useRef();
    const dragRef = useRef();
    const [gameStateCheck, setGameStateCheck] = useState(false);
    const [itterations, setItterations] = useState(1);
    const [caughtSymbols, setCaughtSymbols] = useRecoilState(caughtSymbolsState);
    const [gameStateChecker, setGameStateChecker] = useState(false);
    const [slotIndex, setSlotIndex] = useState(0);

    let currentAnimation = null;
    let lottieAnim;
    let audioUrl = './sfx/level_1/Fishing_Boat_at_Sea_v4.mp3';

    React.useEffect(() => {
        const anim = Lottie.loadAnimation({
            container: animationContainer.current,
            animationData: animation1,
            autoplay: false,
            loop: false
        });
        lottieAnim = anim;
        lottieAnim.stop();
        lottieAnim.addEventListener('loaded_images', function () {
            lottieAnim.play();
            document.getElementById('loader-init').style.display = "none";
            setGameStateCheck(true);
            if (currentSession.gameState) {
                setTimer(currentSession.gameState.remainingTime+2);
                setScore(currentSession.gameState.totalPoints);
                setCaughtSymbols(currentSession.gameState.opened);
            }
            setTimeout(() => {
                setControlState({ ...controlState, isRunning: true });
            }, 4000);
        });

    }, [])

    React.useEffect(() => {
        isLoading(false);
    });

    const advanceStep = useCallback(() => {
        updateDots((oldDots) => {
            const newDots = [];
            let pixelRatio = window.devicePixelRatio > 1 ? window.devicePixelRatio*1.33 : 1;
            for (let dot of oldDots) {
                const oldX = (
                    fieldRef.current.offsetWidth - dot.size
                ) * dot.x / 100
                const speedVariation = Math.abs(dot.symbol) % 5 === 0 || Math.abs(dot.symbol) % 3 === 0 ? 1 : 1.25;
                const newY = dot.y + SPEED_STEP * speedVariation * controlState.speed / 60 / pixelRatio;
                const newRotation = dot.rotation + SPEED_STEP * controlState.speed / 60 / pixelRatio;
                if (newY <= fieldRef.current.offsetHeight - dot.size / 2) {
                    newDots.push(
                        {
                            ...dot,
                            y: newY,
                            rotation: newRotation,
                        }
                    );
                } else {
                    setCaughtSymbols((currentState) => [
                        ...currentState,
                        '' + dot.symbol,
                    ]);
                }
            }
            return newDots;
        });
        requestRef.current = requestAnimationFrame(advanceStep);
    }, [controlState.speed, updateDots]);

    const spawnDot = useCallback((served, servedIndex) => {
        updateDots((oldDots) => [...oldDots, createDot(served, servedIndex)]);
    }, [updateDots, simboli]);

    useEffect(() => {
        if (gameStateChecker) {
            spawnDot(simboli.symbols, slotIndex);
            if (simboli.symbols.length === 10 && slotIndex > 8) {
                setSlotIndex(0);
            } else if (slotIndex > 8) {
                setSlotIndex(0);
            } else {
                setSlotIndex((currentState) => currentState + 1);
            }
            setGameStateChecker(false);
        }

    }, [gameStateChecker, simboli.symbols]);

    useEffect(() => {
        if (gameStateCheck) {
            setItterations(itterations + 1);
            const sessionData = bonusVockiceOpen({
                session: currentSession.id,
                caughtSymbols: caughtSymbols,
            }).then(res => {
                setSymbols({ ...symbolsState, isRunning: false, symbols: res.gameState.served });
                if (res.gameState.remainingTime === -2) {
                    setScore(res.gameState.totalPoints);
                    setControlState({ ...controlState, isRunning: false });
                    updateDots([]);
                    endScreen(res.gameState.totalPoints);
                }
            }).catch(err => {
                setControlState({ ...controlState, isRunning: false });
                updateDots([]);
                // handleNextLevel();
            });
            setGameStateCheck(false);
        }
    }, [gameStateCheck]);

    const decreaseTimer = useCallback(() => {
        setTimer((oldTimer) => {
            setGameStateChecker(true);
            if (oldTimer > 0) {
                if (Math.round(oldTimer % 2) === 0) {
                    setGameStateCheck(true);
                }
                const newTimer = oldTimer - SPAWN_INTERVAL / 1000;
                return newTimer;
            }
            setGameStateCheck(true);
            setTimeout(() => {
                setControlState({ ...controlState, isRunning: false });
                endScreen(score);
                return 0;
            }, 100);
        });
    }, [setTimer]);

    // useEffect(() => {
    //     setGameStateCheck(true);
    //     if (currentSession.gameState) {
    //         setTimer(currentSession.gameState.remainingTime+5);
    //         setScore(currentSession.gameState.totalPoints);
    //         setCaughtSymbols(currentSession.gameState.opened);
    //     }
    //     setTimeout(() => {
    //         setControlState({ ...controlState, isRunning: true });
    //     }, 4000);
    // }, []);

    useEffect(() => {
        window.addEventListener("beforeunload", alertUser);
        return () => {
          window.removeEventListener("beforeunload", alertUser);
        };
      }, []);
      const alertUser = (e) => {
        e.preventDefault();
        e.returnValue = "";
    };

    useEffect(() => {
        const stop = () => {
            // intervalRef.current && clearInterval(intervalRef.current);
            timerRef.current && clearInterval(timerRef.current);
            requestRef.current && cancelAnimationFrame(requestRef.current);
        }

        if (controlState.isRunning) {
            // intervalRef.current = setInterval(spawnDot, SPAWN_INTERVAL);
            timerRef.current = setInterval(decreaseTimer, SPAWN_INTERVAL);
            requestRef.current = requestAnimationFrame(advanceStep);
        } else {
            stop();
        }
        // return () => stop();
    }, [controlState.isRunning, advanceStep])

    const clear = useCallback(() => {
        setControlState({ ...controlState, isRunning: false, speed: SPEED_STEP });
        updateDots([]);
        // setScore(0);
        setTimer(0);
    }, [setControlState, setScore, updateDots, controlState]);

    const onDotClick = (index) => {
        const pointsWon = calculatePoints(dots[index]);
        // const notifyElement = document.getElementById('notify-' + index);
        const dotX = (
            fieldRef.current.offsetWidth
        ) * dots[index].x / 100
        const totalScore = score + pointsWon;
        setScore(totalScore < 0 ? 0 : totalScore);
        setCaughtSymbols((currentState) => [
            ...currentState,
            dots[index].symbol,
        ]);
        let scoreClass = pointsWon < 0 ? 'points-show negative' : 'points-show positive';

        // console.log("pts: ", dotX, dots[index].y, pointsWon, totalScore, totalScore < 0 ? 0 : totalScore);
        // notifyRef.current.style.opacity = 0.75;
        if (pointsWon !== -3000) {
            setIndividualScore(`${pointsWon} pts`);
            setIndividualScore({ ...individualScoreState, message: `${pointsWon} pts`, x: dotX-75, y: dots[index].y, class: scoreClass });
            setTimeout(() => {
                setIndividualScore({ ...individualScoreState, class: '' });
            }, 1000);
        } else {
            console.log("fateno:", dots[index].y);
        }
        // setCurrentStake(`${pointsWon} pts`);
        // if (dots[index].score < 0) {
        //     notifyElement.className = 'points negative show';
        //     setTimeout(() => {
        //         notifyElement.className = 'points negative';
        //     }, 500);
        // } else {
        //     notifyElement.className = 'points show';
        //     setTimeout(() => {
        //         notifyElement.className = 'points';
        //     }, 500);
        // }
        updateDots(removeDot(dots, index));
    };

    const onCloverClick = (index) => {
        updateDots(removeDot(dots, index));
        const dotX = (
            fieldRef.current.offsetWidth
        ) * dots[index].x / 100
        // console.log("sec: ", dots[index].x, dots[index].y);
        // setCurrentStake(`10 sec`);
        setIndividualScore({ ...individualScoreState, message: `10 sec`, x: dotX-75, y: dots[index].y, class: 'points-show' });
        setCaughtSymbols((currentState) => [
            ...currentState,
            dots[index].symbol,
        ]);
        // setIndividualScore(`10 sec`);
        // notifyRef.current.className = 'points show';
        setTimeout(() => {
            setIndividualScore({ ...individualScoreState, class: '' });
        }, 1000);
    };

    return (
        <div className="main" key="game-src-desktop">
            <div className="game-scr-animation" ref={animationContainer}>
            </div>
            <div className="panel">
                <Control onClear={clear} />
                <Score />
                <Timer />
            </div>
            <div className="field" ref={fieldRef}>
                {dots.map((dot, index) => {
                    const x = (
                        fieldRef.current.offsetWidth - dot.size
                    ) * dot.x / 100;
                    if (dragRef.current.state.x >= x - dot.size * 1.6 
                        && dragRef.current.state.x < x + dot.size / 1.6 
                        && dot.y >= fieldRef.current.offsetHeight - dot.size * 1.9 
                        && dot.y < fieldRef.current.offsetHeight - dot.size / 1.35) {
                        // if (dot.symbol === 8 || dot.symbol === 18) {
                        //     setTimer(timer + 10);
                        //     onCloverClick(index);
                        // } else {
                            onDotClick(index);
                        // }
                    }
                    return <>
                        <Dot
                            key={`dot-${index}`}
                            {...dot}
                            x={x}
                            y={dot.y - 75}
                            index={index} />
                        {/* <div id={`notify-${index}`} className="points show" style={{left: x-dot.size/2, top: dot.y-dot.size/2, width: "15%", height: "5%", position: "absolute"}}>{dot.score} {dot.symbol === 8 || dot.symbol === 18 ? ` sec` : ` pts`}</div> */}
                    </>;
                }
                )}
            </div>
            <ShowWonScore />
            <Draggable ref={dragRef} cancel="strong" bounds="parent" key="draggable_field" axis="x" defaultPosition={{ x: window.innerWidth / 2 - 50, y: 0 }}>
                <div className="box draggablediv chest-wrapper">
                    <div className="no-cursor" style={{ position: "absolute", zIndex: -1 }}><img src='./images/symbols/bucket-mobile.png' /></div>
                    {/* <div ref={notifyRef} className="points">{currentStake}</div> */}
                    <div style={{ width: "100px", height: "87px" }}></div>
                </div>
            </Draggable>
        </div>
    );
}

export default Game;
